import React, { FunctionComponent, memo } from "react";

const BrandedIconComponent: FunctionComponent = () => (
  <svg
    width="54" height="54"
    viewBox="0 0 54 54" fill="none"
    xmlns="http://www.w3.org/2000/svg" className="svg-icon"
  >
    <path d="M0.333008 11C0.333008 6.98665 2.41301 2.78665 5.66634 0.333313C5.66634 2.58665 7.50634 5.66665 10.9997 5.66665C13.1214 5.66665 15.1562 6.5095 16.6565 8.00979C18.1568 9.51008 18.9997 11.5449 18.9997 13.6666C18.9997 14.696 18.7863 15.672 18.4343 16.5733C20.4077 18.1573 22.3703 19.7466 24.2743 21.4L18.733 26.9466C17.0911 25.0279 15.4846 23.0791 13.9143 21.1013C13.0077 21.4533 12.0317 21.6666 10.9997 21.6666C5.10634 21.6666 0.333008 16.8933 0.333008 11ZM32.7383 29.2613L36.3117 25.688C37.9694 26.5381 39.8034 26.9875 41.6663 27C44.8489 27 47.9012 25.7357 50.1516 23.4853C52.4021 21.2348 53.6663 18.1826 53.6663 15C53.6663 13.0666 53.165 11.2666 52.3517 9.64798L42.9997 19L37.6663 13.6666L47.0183 4.31465C45.3622 3.46249 43.5288 3.01213 41.6663 2.99998C38.4837 2.99998 35.4315 4.26426 33.1811 6.5147C30.9306 8.76513 29.6663 11.8174 29.6663 15C29.6663 16.9333 30.1677 18.7333 30.981 20.352L2.99967 48.3333L8.33301 53.6666L26.5943 35.4053C31.6173 40.5584 36.9394 45.4113 42.533 49.9386L46.333 53L50.333 49L47.2663 45.2C42.7423 39.6052 37.8912 34.283 32.7383 29.2613Z" fill="currentColor" />
  </svg>
);

export const BrandedIcon = memo(BrandedIconComponent);