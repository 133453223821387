import { LocalVideoTrack } from "twilio-video";
import { useCallback, useRef, useState, useEffect } from "react";
import useVideoContext from "../useVideoContext/useVideoContext";

export default function useLocalVideoToggle() {
    const {
        room,
        localTracks,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        onError,
    } = useVideoContext();
    const { localParticipant, isPublishingVideo } = room;
    const videoTrack = localTracks.find(track => track.name.includes("camera")) as LocalVideoTrack;
    const [isPublishing, setIsPublishing] = useState(isPublishingVideo);
    const [didConnectOnce, setDidConnectOnce] = useState(isPublishingVideo);
    const [isVideoEnabled, setisVideoEnabled] = useState(false);
    const previousDeviceIdRef = useRef<string>();

    const isDisabledRemote = localParticipant && !isPublishing;
    const shouldHideBtn = room?.isVideoProducingDisabled;

    const isConnected = !!localParticipant;

    useEffect(() => {
        setDidConnectOnce(room.isPublishingVideo);
    }, [room]);

    useEffect(() => {
        toggleVideoEnabled();
        return () => {};
    }, []);

    useEffect(() => {
        setisVideoEnabled(!!videoTrack);
        return () => {};
    }, [videoTrack, isDisabledRemote]);

    const toggleVideoEnabled = useCallback(() => {
        if (videoTrack && isPublishing) {
            previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
            localParticipant?.unpublishTrack(videoTrack);
            removeLocalVideoTrack();
        } else {
            getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
                .then((track: LocalVideoTrack) =>
                    localParticipant?.publishTrack(track, { priority: "low" }),
                )
                .catch(onError)
                .finally(() => setIsPublishing(true));
        }
    }, [
        videoTrack,
        localParticipant,
        getLocalVideoTrack,
        isPublishing,
        onError,
        removeLocalVideoTrack,
    ]);

    useEffect(() => {
        if (room.isPublishingVideo) {
            setDidConnectOnce(true);
        } else if (isConnected && videoTrack && !didConnectOnce && !isPublishing) {
            setIsPublishing(true);
            setDidConnectOnce(true);
            localParticipant?.publishTrack(videoTrack);
        }
    }, [isConnected, videoTrack, didConnectOnce, isPublishing, room]);

    return [isVideoEnabled, toggleVideoEnabled, shouldHideBtn] as const;
}
