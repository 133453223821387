import { useEffect, useState } from "react";

import {
    IGame,
    ITournamentDetails,
    ITournamentPlayer,
    TournamentStatus,
} from "../../../engine/types";

interface IShouldShowFinishedDialogParams {
    currentPlayer?: ITournamentPlayer;
    table?: IGame;
    tournament?: ITournamentDetails;
    declinedRebuy?: boolean;
    showRebuy?: boolean;
}

export default function useShouldShowFinishedDialog({
    currentPlayer,
    tournament,
    table,
    declinedRebuy,
    showRebuy,
}: IShouldShowFinishedDialogParams): boolean {
    const [shouldShowFinishedDialog, setShouldShowFinishedDialog] = useState(false);

    useEffect(() => {
        const isTournamentEnded = tournament?.status === TournamentStatus.Ended;
        const isOnTable = table?.id;
        const showFinishedDialog =
            (currentPlayer?.bustedTimestamp || isTournamentEnded) && isOnTable && !showRebuy;

        if (declinedRebuy) {
            setShouldShowFinishedDialog(true);
        } else {
            if (isTournamentEnded) {
                setShouldShowFinishedDialog(true);
            } else {
                // if (showFinishedDialog) {
                //     setShouldShowFinishedDialog(true);
                // } else {
                //     setShouldShowFinishedDialog(false);
                // }
            }
        }
    }, [
        // currentPlayer?.stack,
        // currentPlayer?.bustedTimestamp,
        tournament?.status,
        // table?.id,
        declinedRebuy,
        // showRebuy,
    ]);

    return shouldShowFinishedDialog;
}
