import React, { FunctionComponent, memo } from "react";

const FunIconComponent: FunctionComponent = () => (
  <svg
    width="64" height="64"
    viewBox="0 0 64 64" fill="none"
    xmlns="http://www.w3.org/2000/svg" className="svg-icon"
  >
    <path d="M24.6547 41.2034L23.6728 47.0356C23.5389 47.833 23.8661 48.6365 24.5188 49.1123C25.1726 49.5872 26.0407 49.6507 26.755 49.2764L31.9986 46.5393L37.2421 49.2769C37.5524 49.4379 37.8923 49.519 38.2287 49.519C38.6692 49.519 39.1086 49.381 39.4793 49.1128C40.1326 48.637 40.4608 47.8335 40.3259 47.0361L39.345 41.2039L43.5671 37.0623C44.1445 36.4967 44.3501 35.6527 44.1024 34.8846C43.8538 34.117 43.1882 33.555 42.3893 33.437L36.5386 32.5694L33.9035 27.2731C33.5461 26.5501 32.8057 26.0922 31.9981 26.0922C31.192 26.0922 30.4521 26.5501 30.0916 27.2731L27.4591 32.5694L21.6084 33.4365C20.8079 33.5545 20.1455 34.1165 19.8963 34.8841C19.647 35.6522 19.8522 36.4972 20.4306 37.0618L24.6547 41.2034Z" fill="currentColor" />
    <path d="M57.1443 6.80391H50.445V3.08788C50.445 1.38242 49.0626 0 47.3566 0H46.8669C45.1615 0 43.7795 1.38242 43.7795 3.08788V6.80391H20.1425V3.08788C20.1425 1.38242 18.7601 0 17.0551 0H16.5649C14.8595 0 13.4776 1.38242 13.4776 3.08788V6.80391H6.85621C3.35247 6.80391 0.500977 9.65438 0.500977 13.1591V57.6453C0.500977 61.148 3.35247 64.0005 6.85621 64.0005H57.1448C60.648 64.0005 63.5001 61.1485 63.5001 57.6453V13.1591C63.4995 9.65438 60.6475 6.80391 57.1443 6.80391ZM56.3152 56.8161H7.68536V22.1864H56.3152V56.8161Z" fill="currentColor" />
  </svg>
);
export const FunIcon = memo(FunIconComponent);
