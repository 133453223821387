import { configureStore } from "@reduxjs/toolkit";
import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import rightDrawerReducer from "./features/rightDrawer/rightDrawerSlice";
import audioVideoControlReducer from "./features/audioVideoControl/audioVideoControl";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["audioVideoControl"],
};
const persistedReducer = persistReducer(
  persistConfig,
  combineReducers({
    rightDrawer: rightDrawerReducer,
    audioVideoControl: audioVideoControlReducer,
  }),
);
const store = createStore(
  persistedReducer,
  (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__(),
);

const persistor = persistStore(store);
export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
