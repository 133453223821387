import React, { memo, useEffect, useState } from "react";
import clsx from "clsx";
import Fab from "@material-ui/core/Fab";
import Mic from "@material-ui/icons/Mic";
import MicOff from "@material-ui/icons/MicOff";
import Tooltip from "@material-ui/core/Tooltip";

import useLocalAudioToggle from "../../../../../twilio/hooks/useLocalAudioToggle/useLocalAudioToggle";
import { useAppDispatch, useAppSelector } from "../../../../../store/hooks";
import { setisAudioEnabled } from "../../../../../store/features/audioVideoControl/audioVideoControl";
import useRoomState from "../../../../../twilio/hooks/useRoomState/useRoomState";
import useVideoContext from "../../../../../twilio/hooks/useVideoContext/useVideoContext";

interface IToggleAudioButtonProps {
    disabled?: boolean;
    className?: string;
    activeClassName?: string;
    isConnected?: boolean;
}

const ToggleAudioButton = ({
  disabled,
  className,
  activeClassName,
  isConnected,
}: IToggleAudioButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const isAudioEnabledDefault = useAppSelector(state => state.audioVideoControl.isAudioEnabled);
  const [isAudioEnabledDefaultState, setisAudioEnabledDefaultState] = useState(
    isAudioEnabledDefault,
  );

  const [isAudioEnabled, toggleAudioEnabled, shouldHideBtn] = useLocalAudioToggle();

  const {
    room: { localParticipant, state },
  } = useVideoContext();

  useEffect(() => {
    if (state === "connected") {
      if (isAudioEnabledDefaultState !== isAudioEnabled) {
        setTimeout(() => {
          toggleAudioEnabled();
          console.log("default audio disable");
        }, 2000);
      }
    }
    return () => {};
  }, [state]);

  if (shouldHideBtn) {
    return null;
  }

  return (
    <Tooltip
      title={isAudioEnabled ? "Mute Audio" : "Unmute Audio"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab
        className={clsx(className, { [activeClassName]: isAudioEnabled })}
        onClick={() => {
          toggleAudioEnabled();
          dispatch(setisAudioEnabled(!isAudioEnabledDefault));
        }}
        onContextMenu={() => {}}
        disabled={disabled}
        data-cy-audio-toggle
      >
        {isAudioEnabled ? <Mic /> : <MicOff />}
      </Fab>
    </Tooltip>
  );
};

export default memo(ToggleAudioButton);
