import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RightDrawerTabs } from "../../../components/Tournament/components/RightDrawer/types";

interface IRightDrawerState {
  isAudioEnabled: boolean;
  isVideoEnabled: boolean;
}

const initialState: IRightDrawerState = {
  isAudioEnabled: true,
  isVideoEnabled: true,
};

export const rightDrawerSlice = createSlice({
  name: "rightDrawer",
  initialState,
  reducers: {
    setisAudioEnabled: (state, action: PayloadAction<boolean>): void => {
      state.isAudioEnabled = action.payload;
    },
    setisVideoEnabled: (state, action: PayloadAction<boolean>): void => {
      state.isVideoEnabled = action.payload;
    },
  },
});

export const { setisAudioEnabled, setisVideoEnabled } = rightDrawerSlice.actions;

export default rightDrawerSlice.reducer;
