import React, { FunctionComponent, memo } from "react";

const GroupIconComponent: FunctionComponent = () => (
  <svg
    width="57" height="56"
    viewBox="0 0 57 56" fill="none"
    xmlns="http://www.w3.org/2000/svg" className="svg-icon"
  >
    <path d="M8 8C8 5.87827 8.84285 3.84344 10.3431 2.34315C11.8434 0.842855 13.8783 0 16 0C18.1217 0 20.1566 0.842855 21.6569 2.34315C23.1571 3.84344 24 5.87827 24 8C24 10.1217 23.1571 12.1566 21.6569 13.6569C20.1566 15.1571 18.1217 16 16 16C13.8783 16 11.8434 15.1571 10.3431 13.6569C8.84285 12.1566 8 10.1217 8 8Z" fill="currentColor" />
    <path d="M35.115 14.3359L35.283 14.4639C36.9802 15.7219 39.106 16.2576 41.1967 15.954C43.2873 15.6503 45.173 14.5321 46.4422 12.8433C47.7114 11.1544 48.2611 9.03213 47.9713 6.93952C47.6814 4.84691 46.5757 2.95388 44.8953 1.67356C43.2148 0.393247 41.0962 -0.170416 39.0017 0.105586C36.9073 0.381588 35.007 1.47485 33.7156 3.14678C32.4242 4.81871 31.8466 6.93356 32.1087 9.02982C32.3709 11.1261 33.4516 13.0335 35.115 14.3359Z" fill="currentColor" />
    <path d="M21.0704 20.0003C21.5728 19.049 22.262 18.2088 23.0966 17.5299C23.9312 16.851 24.8942 16.3475 25.928 16.0494C26.9617 15.7513 28.0449 15.6648 29.1129 15.795C30.1808 15.9253 31.2115 16.2696 32.1434 16.8073C33.0752 17.3451 33.8889 18.0653 34.536 18.9248C35.183 19.7844 35.6501 20.7656 35.9091 21.8098C36.1682 22.854 36.214 23.9397 36.0438 25.002C35.8735 26.0643 35.4908 27.0814 34.9184 27.9923C33.8173 29.7449 32.0798 31.0024 30.071 31.5008C28.0621 31.9992 25.9383 31.6997 24.1457 30.6651C22.3531 29.6305 21.0312 27.9415 20.4578 25.9528C19.8843 23.9641 20.1039 21.8305 21.0704 20.0003Z" fill="currentColor" />
    <path d="M6 20H16.68C16.24 21.252 16 22.6 16 24C16 27.072 17.156 29.88 19.056 32H18C15.9377 31.9997 13.9258 32.6368 12.2395 33.8239C10.5533 35.0111 9.27511 36.6904 8.58 38.632C7.23733 38.0926 5.98019 37.361 4.848 36.46C1.84 34.04 0 30.464 0 26C0 24.4087 0.632141 22.8826 1.75736 21.7574C2.88258 20.6321 4.4087 20 6 20Z" fill="currentColor" />
    <path d="M38.0013 32C42.3413 32 46.0373 34.764 47.4173 38.632C48.7773 38.076 50.0373 37.352 51.1533 36.46C54.1613 34.04 56.0013 30.464 56.0013 26C56.0013 24.4087 55.3692 22.8826 54.2439 21.7574C53.1187 20.6321 51.5926 20 50.0013 20H39.3213C39.7613 21.252 40.0013 22.6 40.0013 24C40.005 26.9525 38.9165 29.8019 36.9453 32H38.0013Z" fill="currentColor" />
    <path d="M43.548 39.712C43.84 40.416 44 41.192 44 42C44 46.464 42.164 50.04 39.152 52.46C36.188 54.84 32.212 56 28 56C23.788 56 19.812 54.84 16.848 52.46C13.84 50.04 12 46.464 12 42C11.9979 41.2115 12.1517 40.4303 12.4524 39.7014C12.7532 38.9725 13.1951 38.3102 13.7527 37.7527C14.3102 37.1951 14.9725 36.7532 15.7014 36.4524C16.4303 36.1517 17.2115 35.9979 18 36H38C39.1884 35.9997 40.3502 36.3524 41.3379 37.0133C42.3256 37.6741 43.0948 38.6134 43.548 39.712Z" fill="currentColor" />
  </svg>
);
export const GroupIcon = memo(GroupIconComponent);