import React, { FunctionComponent, memo } from "react";

const HandIconComponent: FunctionComponent = () => (
  <svg
    width="64" height="64"
    viewBox="0 0 64 64" fill="none"
    xmlns="http://www.w3.org/2000/svg" className="svg-icon"
  >
    <path d="M63.3574 30.2643C63.3579 29.5118 63.065 28.8045 62.533 28.2725C61.441 27.1807 59.66 27.1762 58.5625 28.2622C57.962 28.8445 47.7082 39.0679 46.811 39.9601C45.9331 40.8342 44.5124 40.8317 43.6379 39.9541C42.7633 39.0763 42.766 37.6556 43.6439 36.7809C49.0681 31.3736 57.7247 22.752 63.1529 17.3453C63.6875 16.8133 63.9819 16.1059 63.9827 15.3528C63.9837 14.5997 63.6909 13.8916 63.1582 13.359L63.0807 13.2815C61.9859 12.1867 60.2023 12.1847 59.1046 13.2768C55.5547 16.8076 45.2481 27.0742 41.5784 30.7297C40.7007 31.6041 39.2803 31.6014 38.4057 30.7237C37.5312 29.8461 37.5334 28.4256 38.4109 27.551C44.0148 21.9605 51.7161 14.3011 57.301 8.73536C57.835 8.20374 58.1294 7.49637 58.1303 6.74338C58.1311 5.99038 57.8383 5.28226 57.3059 4.74989L57.2107 4.65464C56.6789 4.12302 55.972 3.83015 55.2202 3.83015C54.4683 3.83015 53.7616 4.1229 53.2299 4.65464L34.3855 23.4988C33.5093 24.3749 32.0886 24.3749 31.2124 23.4988C30.3361 22.6226 30.3361 21.2019 31.2124 20.3256L46.6734 4.86477C47.205 4.33302 47.4977 3.62615 47.4977 2.87428C47.4977 2.12241 47.205 1.41567 46.6734 0.884049L46.6105 0.821049C45.5149 -0.274317 43.729 -0.274692 42.6315 0.823049L17.4711 25.9837C16.7601 26.6947 15.663 26.8458 14.7862 26.3537C13.9095 25.8616 13.4671 24.8464 13.7039 23.8691L18.0072 6.09826C18.4267 4.36615 17.4802 2.59991 15.8056 1.98979C15.4106 1.84592 15.0047 1.77429 14.6016 1.77429C14.0289 1.77429 13.4612 1.91842 12.9368 2.20404C12.0429 2.69066 11.4259 3.50503 11.1995 4.49715L10.8671 5.95338C9.19103 13.2961 6.4278 20.6601 2.65408 27.841C0.3981 32.1334 -0.460768 37.1561 0.235726 41.9836C0.95547 46.9729 3.22608 51.5004 6.80193 55.0763C11.1909 59.4652 17.0252 61.8818 23.2315 61.8818H23.2473C29.4595 61.8777 35.2968 59.453 39.6838 55.0544L45.2754 49.4478L62.532 32.2542C63.0635 31.7239 63.357 31.0167 63.3574 30.2643Z" fill="currentColor" />
    <path d="M57.9083 48.7691C58.3158 47.8173 57.8744 46.715 56.9225 46.3077C55.9705 45.8999 54.8684 46.3414 54.4609 47.2934C53.8319 48.7626 52.9341 50.0865 51.7926 51.228C50.398 52.6225 48.7501 53.6434 46.8946 54.2623C45.9123 54.5899 45.3815 55.6518 45.7091 56.6343C45.9711 57.4198 46.7026 57.9165 47.4876 57.9165C47.6843 57.9165 47.8843 57.8854 48.0811 57.8198C50.4936 57.015 52.6346 55.6895 54.4444 53.8796C55.9249 52.3988 57.0904 50.6794 57.9083 48.7691Z" fill="currentColor" />
    <path d="M62.8636 49.8032C61.9118 49.3952 60.8095 49.8358 60.4015 50.7877C59.5254 52.8313 58.2761 54.6725 56.6885 56.2602C54.842 58.1067 52.67 59.486 50.233 60.3597C49.2582 60.7092 48.7513 61.7827 49.1008 62.7575C49.3753 63.5233 50.0966 64.0002 50.8659 64.0002C51.0759 64.0002 51.2895 63.9647 51.4986 63.8897C54.4609 62.8277 57.0991 61.1528 59.3401 58.9118C61.2666 56.9854 62.7833 54.7492 63.8481 52.2652C64.2563 51.3134 63.8154 50.2111 62.8636 49.8032Z" fill="currentColor" />
  </svg>
);
export const HandIcon = memo(HandIconComponent);