

import { LocalAudioTrack } from "twilio-video";
import { useCallback, useState, useEffect, useRef } from "react";
import useIsTrackEnabled from "../useIsTrackEnabled/useIsTrackEnabled";
import useVideoContext from "../useVideoContext/useVideoContext";
import useRoomState from '../useRoomState/useRoomState';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';

export default function useLocalAudioToggle() {
  const {
    room,
    localTracks,
    getLocalAudioTrack,
    removeLocalAudioTrack,
    onError,
  } = useVideoContext();
  const { localParticipant, isPublishingAudio } = room;
  const audioTrack = localTracks.find(track => track.kind === "audio") as LocalAudioTrack;
  const [isPublishing, setIsPublishing] = useState(isPublishingAudio);
  const [didConnectOnce, setDidConnectOnce] = useState(isPublishingAudio);
  const [isAudioEnabled, setisAudioEnabled] = useState(false);
  const previousDeviceIdRef = useRef<string>();

  const isDisabledRemote = localParticipant && !isPublishing;
  const shouldHideBtn = room?.isVideoProducingDisabled;

  const isConnected = !!localParticipant;

  useEffect(() => {
    setDidConnectOnce(room.isPublishingAudio);
  }, [room]);

  useEffect(() => {
    toggleAudioEnabled();
    return () => {};
  }, []);

  useEffect(() => {
    setisAudioEnabled(!!audioTrack);
    return () => {};
  }, [audioTrack, isDisabledRemote]);

  const toggleAudioEnabled = useCallback(() => {
    if (audioTrack && isPublishing) {
      previousDeviceIdRef.current = audioTrack.mediaStreamTrack.getSettings().deviceId;
      localParticipant?.unpublishTrack(audioTrack);
      removeLocalAudioTrack();
    } else {
      getLocalAudioTrack()
        .then((track: LocalAudioTrack) => localParticipant?.publishTrack(track, { priority: "low" }))
        .finally(() => setIsPublishing(true));
    }
  }, [
    audioTrack,
    localParticipant,
    getLocalAudioTrack,
    isPublishing,
    onError,
    removeLocalAudioTrack,
  ]);

  useEffect(() => {
    console.log("audioTrack", audioTrack);
    console.log("localTracks", localTracks);

    return () => {
    }
  }, [audioTrack])


  useEffect(() => {
    if (room.isPublishingAudio) {
      setDidConnectOnce(true);
    } else if (isConnected && audioTrack && !didConnectOnce && !isPublishing) {
      setIsPublishing(true);
      setDidConnectOnce(true);
      localParticipant?.publishTrack(audioTrack);
    }
  }, [isConnected, audioTrack, didConnectOnce, isPublishing, room]);

  return [isAudioEnabled, toggleAudioEnabled, shouldHideBtn] as const;
}

// import { LocalAudioTrack } from "twilio-video";
// import { useCallback, useState, useEffect } from "react";
// import useIsTrackEnabled from "../useIsTrackEnabled/useIsTrackEnabled";
// import useVideoContext from "../useVideoContext/useVideoContext";
// import useRoomState from '../useRoomState/useRoomState';
// import { useAppDispatch, useAppSelector } from '../../../store/hooks';

// export default function useLocalAudioToggle() {
//   const {
//     localTracks,
//     getLocalAudioTrack,
//     removeLocalAudioTrack,
//     room: { localParticipant, isPublishingAudio },
//   } = useVideoContext();
//   const dispatch = useAppDispatch();
//   const isAudioEnabledDefault = useAppSelector(state => state.audioVideoControl.isAudioEnabled);

//   const [isPublishing, setIsPublishing] = useState(isPublishingAudio);
//   const [didConnectOnce, setdidConnectOnce] = useState(isPublishingAudio);

//   const audioTrack = localTracks.find(track => track.kind === "audio") as LocalAudioTrack;

//   const isEnabled = useIsTrackEnabled(audioTrack);
//   const isConnected = !!localParticipant;
//   const isAudioEnabled = isEnabled && (isPublishing || !isConnected);

//   const toggleAudioEnabled = useCallback(() => {
//     const shouldDisableAudioTrack = audioTrack && isPublishing;
//     if (shouldDisableAudioTrack) {
//       disableAudioTrack();
//     } else {
//       enableAudioTrack();
//     }
//   }, [audioTrack, localParticipant, isPublishing]);

//   const disableAudioTrack = useCallback(() => {
//     setIsPublishing(false);
//     removeLocalAudioTrack();
//     localParticipant?.unpublishTrack(audioTrack);
//   }, [isPublishing, localParticipant]);

//   const enableAudioTrack = useCallback(() => {
//     getLocalAudioTrack()
//       .then((track: LocalAudioTrack) => localParticipant?.publishTrack(track, { priority: "low" }))
//       .finally(() => setIsPublishing(true));
//   }, [localParticipant]);

//   useEffect(() => {
//     if (isConnected && audioTrack && !didConnectOnce && !isPublishing) {
//       setIsPublishing(true);
//       setdidConnectOnce(true);
//       localParticipant?.publishTrack(audioTrack);
//     }
//   }, [isConnected, audioTrack, didConnectOnce, isPublishing]);

//   return [isAudioEnabled, toggleAudioEnabled, isPublishing] as const;
// }
